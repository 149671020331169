/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React, { useEffect } from 'react'
import 'rc-collapse/assets/index.css'
import { getScrollOffsetTopForElementId } from './src/helpers/navigate'

// https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#shouldUpdateScroll
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  const elementId = location.hash ? location.hash.replace('#', '') : undefined
  if (elementId) {
    const newScrollOffsetTop = getScrollOffsetTopForElementId(elementId)
    window.scrollTo({ top: newScrollOffsetTop })
    return false
  }

  return true
}

/* Tmp solution to hide wrongly hydrated JSON data */
const RootWrapper = ({ element }) => {
  useEffect(() => {
    // target only direct child nodes under body
    // const bodyChildren = document.body.children
    // Array.from(bodyChildren).forEach(node => {
    //   if (node.nodeType === Node.TEXT_NODE && node.textContent.trim()) {
    //     node.remove() // remove stray text nodes
    //   }
    // })

    const removeTextNodes = () => {
      const bodyChildren = document.body.children
      Array.from(bodyChildren).forEach(node => {
        // document.body.childNodes.forEach(node => {
        if (node.nodeType === Node.TEXT_NODE && node.textContent.trim()) {
          node.remove() // remove stray text nodes
        }
      })
    }

    if ('requestIdleCallback' in window) {
      requestIdleCallback(removeTextNodes)
    } else {
      setTimeout(removeTextNodes, 0)
    }
  }, [])

  return <>{element}</>
}

export const wrapRootElement = ({ element }) => {
  return <RootWrapper element={element} />
}
